/**
 * 프로젝트에서 공통으로 사용되는 글로벌 저장 변수
 */

import {createStore} from "vuex";
import createPersistedState from "vuex-persistedstate"
import userInfoStore from "@/store/userInfoStore";
import prevUrlStore from "@/store/prevUrlStore";
import surveyListDataStore from "@/store/surveyListDataStore";
import newTabParamsStore from "@/store/newTabParamsStore";
import adminInfoStore from "@/store/adminInfoStore";
import ttsLockStore from "@/store/ttsLockStore";

export default createStore({

  modules: {
    userInfoStore : userInfoStore,
    adminInfoStore : adminInfoStore,
    prevUrlStore : prevUrlStore,
    surveyListDataStore : surveyListDataStore,
    newTabParamsStore : newTabParamsStore,
    ttsLockStore : ttsLockStore,
  },
  // store 내용을 localstorage와 sync 맞춤
  plugins : [ createPersistedState({
    key: 'userinfoKey',
    paths: ['userInfoStore.userInfo', 'adminInfoStore.adminInfo', 'adminInfoStore.isLoggedInAdmin', 'userInfoStore.isLoggedIn', 'surveyListDataStore.surveyDataList'
      ,'surveyListDataStore.fileDataList','newTabParamsStore.params','ttsLockStore.isProcessingTTS']
  })]
})
