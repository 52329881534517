<script setup>

import { Carousel, Slide, Navigation  } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import {computed, onMounted, reactive, ref} from "vue";
import {ApiShopping} from "@/api/api.shopping";
import {ApiContent} from "@/api/api.content";
import {ApiSurvey} from "@/api/api.survey";

import {useStore} from "vuex";
import router from "@/router";

const store = useStore()
let sessionLogin = sessionStorage.getItem('sessionLogin')

// 사용자 이름 (환자 로그인 시 사용)
const userInfo = computed(()=> store.getters.getUserInfo)
const userName = userInfo.value.patiName
const surgeryDate = computed(()=> store.getters.getUserInfo).value.patiSurgeryDate // 수술일
const admId = computed(()=> store.getters.getUserInfo).value.admId // 환자 담당 의사Id

const adminInfo = computed(()=>store.getters.getAdminInfo)

const shoppingHelpList = ref([])
const sortList = ref([]) // 분류
const descriptionPop = ref(false) // 설명 팝업

/**
 * 쇼핑/도움서비스
 * @type {getShopList}
 */
const getShopList = (()=>{
  ApiShopping.getShopList().then(res=>{
    if(res.resultCode === "E0000") {
      shoppingHelpList.value = res.result.list
    }
  })

  ApiShopping.sortList().then(res=>{
    if(res.resultCode === "E0000") {
      for(let i=0; i<res.result.list.length; i++) {
        sortList.value.push(res.result.list[i].sort)
      }
    }
  })
})

// 분류별 리스트
const currentList = (list, key) => {
  if(Array.isArray(list) && list.length !== 0) {
    return list.filter(item => item.sort == key)
  }
}

/**
 * 콘텐츠 목록
 * @type {Ref<UnwrapRef<*[]>>}
 */
const contentList = ref([])
const getContentList = (()=> {

  ApiContent.allList().then(res=>{
    if(res.resultCode === "E0000") {
      contentList.value = res.result.list
    }
  })
})

// 텍스트 콘텐츠 -> 콘텐츠 내용에 있는 첫번째 이미지 가져오기
const findImg = ((item) => {
  const findImage = /<img[^>]+src="([^">]+)"/; // 이미지 찾는 정규식

  const match = item.match(findImage);

  if(match && match[1]) {
    const imagePath = match[1].replace(/&amp;/g, '&'); // 기호표시 변경
    return imagePath
  } else {
    return null
  }
})

const fnGoToDetail = ((item)=>{
  router.push({
    path:'/detail',
    query : {"contentSeq" : item.contentSeq, "contentType" : 'CONTENT'}
  })
})

// 화면 크기 별 보일 콘텐츠 갯수(쇼핑/도움서비스)
const getItemCount = ()=>{
  const viewWidth = window.innerWidth;
  if(viewWidth <= 767) {
    return 3
  } else {
    return 4
  }
}

/**
 * 수술 후 경과일
 * @type {Ref<UnwrapRef<string>>}
 */
const countDay = ref('') // 수술 경과일
const countDate = () => {
  let today = new Date()
  let surgery = new Date(surgeryDate)

  let diff = Math.abs(today.getTime() - surgery.getTime())
  diff = Math.ceil(diff / (1000 * 60 * 60 * 24))

  countDay.value = diff
  // 이후 추천 콘텐츠 목록(프리셋) 가져오기
  // 수술 후 경과일이 프리셋 테이블의 display_start와 display_end 사이에 있는 거 가져오기!
  getContentListByUser()
}

/**
 * 환자별 콘텐츠 목록(수술 경과일 기준)
 * @type {Ref<UnwrapRef<*[]>>}
 */
const userContentList = ref([])
const getContentListByUser = () => {

  /* let patiId = userInfo.value.patiId

  console.log("params", patiId)

  ApiContent.getContentListByUser(patiId).then(res=>{
    if(res.resultCode === "E0000") {
      userContentList.value = res.result.list
      console.log("목록", userContentList.value)
    } else {
      userContentList.value = null
    }
  }) */
  let params = {
    "admId" : admId, // 의사 ID
    "postYn" : 'Y', // 게시여부
    "surgeryDate" : countDay.value // 수술 경과일
  }

  ApiContent.getPresetList(params).then(res=>{
    if(res.resultCode === "E0000") {
      console.log("성공", res.result.list)
      userContentList.value = res.result.list[0].contentsList
    }
  })
}


onMounted(() => {

  getShopList();
  getContentList()

  // 환자 로그인시에만 실행
  if( Object.keys(adminInfo.value).length === 0 && Object.keys(userInfo.value).length !== 0) {
    countDate();

    getContentListByUser();
  }
})

// 새로운 설문 시작 선택 시
const goNewSurvey= () => {

  let params = {
    'sid': 86,
    'patiSeq': userInfo.value.patiSeq,
    'surveyType': 'single'
  }

  ApiSurvey.linkedSurveyOne(params).then(res => {

    if (res.resultCode === "E0000") {

      let valuesArray = [];

      valuesArray.push(res.result.info);

      let params = {
        'surveyType' : 'single',
        'patiSeq': userInfo.value.patiSeq,
        'surveyCycleList' : valuesArray
      }

      store.commit('setParams', params);

      router.push({
        path: '/surveyRealFinalSelf'
      })
    }
  })
}

// 설명 팝업
const serviceInfo = reactive({
  "title" : '',
  "description" : '',
  "link" : ''
})
// 설명 팝업 열기
const descriptionOpen = (item) => {
  serviceInfo.title = item.title
  serviceInfo.description = item.description
  serviceInfo.link = item.link

  descriptionPop.value = true
}

// 링크이동 구현중
/* eslint-disable no-unused-vars */
const moveLink = (link) => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const urlLink = link;

  // url에서 검색어 추출
  const urlParams = new URLSearchParams(new URL(urlLink).search)
  const searchQuery = urlParams.get("q")

  if(/android/i.test(userAgent)) {
    window.location.href = urlLink
  } else if(/iPhone|iPad|iPod/i.test(userAgent)) {
    // app store 검색으로 이동
    window.location.href = `https://www.apple.com/kr/search/${encodeURIComponent(searchQuery)}?src=globalnav`;
  } else {
    window.location.href = urlLink
  }
}

</script>

<template>
  <!-- container -->
  <div class="container">
    <!-- content -->
    <div class="content content_main">
      <div class="main_notice" v-show="sessionLogin == null && userName == null">
        <p><b>로그인 후 부가정보를 입력</b>해주시면 <br>
          귀하의 암 여정에 맞는 유익한 정보를 얻으실 수 있습니다.</p>
      </div>

      <!-- 로그인 후, 부가정보 입력 후  노출-->
      <div class="main_top" v-if="sessionLogin != null && userName != null">
          <div class="column01">
            <p class="text01">{{userName}}님. <!--<span>수술 후 50일 차</span>입니다.--></p>
            <p class="text02">귀하의 <b>암 여정에 맞는 정보</b>를 알아볼까요?</p>
          </div>
          <div class="column02">
            <p class="text03">위암 수술 후의 생활은 어떠신가요?</p>
            <p class="text04">설문을 통해 자가진단이 가능합니다.</p>
            <button class="btn_round" @click="goNewSurvey()"><i class="ico_document"></i>자가진단</button>
          </div>
      </div>

      <!-- card_section -->
      <div class="card_section main_first">
        <div class="card_head">
          <h3 class="section_title">알면 알수록 건강해지는 <b>의학정보</b></h3>

        </div>
        <div class="card_body">
          <!-- 추천 콘텐츠 : 로그인 후 & 부가정보 입력 후 노출-->
          <div class="recommend_content" v-show="sessionLogin != null && Object.keys(adminInfo).length === 0 && userContentList.length > 0">
            <div class="title">수술 {{countDay}}일차 추천 콘텐츠</div>
            <div class="card_list type-a">
              <ul>
                <li class="card_item" v-for="(item, index) in userContentList" :key="index">
                  <div class="card" :class="{'no_image' : findImg(item.content) == null || findImg(item.content) == ''}" @click="fnGoToDetail(item)" style="cursor: pointer;">
                    <div class="img" v-if="findImg(item.content) != null && findImg(item.content) != ''">
                      <img :src="findImg(item.content)">
                    </div>
                    <div class="cont">
                      <div class="card_cate">{{item.firstName}}</div>
                      <div class="card_title">{{item.title}}</div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- 기본 콘텐츠 목록 -->
          <div class="recommend_content">
            <div class="title">수술 시기별 콘텐츠</div>
            <div class="card_list type-a">
              <ul>
                <li class="card_item" v-for="(item, index) in contentList" :key="index">
                  <div class="card" :class="{'no_image' : findImg(item.content) == null || findImg(item.content) == ''}" @click="fnGoToDetail(item)" style="cursor: pointer;">
                    <div class="img">
                      <img :src="findImg(item.content)">
                    </div>
                    <div class="cont">
                      <div class="card_cate">{{item.firstName}}</div>
                      <div class="card_title">{{item.title}}</div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="more_area">
            <router-link to="/content"><button class="btn_more"><i class="ico_more"></i>더보기</button></router-link>
          </div>
        </div>
      </div>
      <!-- // card_section-->

      <!-- card_section -->
      <div class="card_section bg_green">
        <div class="card_head">
          <router-link to="/serviceInfo"><h3 class="section_title">쇼핑‧도움서비스</h3></router-link>
        </div>
        <div class="card_body" v-for="(item, index) in sortList" :key="index" :style="{'margin-bottom': index != (sortList.length - 1) ? '5rem' : ''}">
          <div class="card_list type-c">
            <h4 class="card_list_title">{{item}}</h4>
            <Carousel :items-to-show="getItemCount()" :breakpoints="null" v-if="shoppingHelpList != '' && currentList(shoppingHelpList, item) != ''">
              <Slide class="card_item" v-for="(sitem, sindex) in currentList(shoppingHelpList, item)" :key="sindex">
                <div class="card" @click="descriptionOpen(sitem)" style="cursor:pointer;">
                  <div class="img" :class="{'no_image':sitem.saveFileName == ''}">
                    <img v-if="sitem.saveFileName != ''" :src="sitem.filePath + sitem.saveFileName">
                  </div>
                  <div class="cont">
                    <div class="card_title">{{sitem.title}}</div>
<!--                <a :href="sitem.link" class="btn_arrow" target="_blank" title="홈페이지로 이동"><i class="ico_arrow"></i></a>   -->
                  </div>
                </div>
              </Slide>
              <template #addons>
                <navigation v-if="currentList(shoppingHelpList, item).length >= 4"/>
              </template>
            </Carousel>
          </div>
        </div>
      </div>
      <!-- // card_section -->
    </div>
    <!-- //content -->
    <div class="popup_wrap" :class="{'show' : descriptionPop}" @click="descriptionPop = false">
      <div class="popup_cont popup_alert" @click.stop>
        <div class="pop_head">
          <h2 style="color: #229CE0;">{{serviceInfo.title}}</h2>
          <a href="javascript:void(0)" class="btn_layerClose pop_close" @click="descriptionPop = false">닫기</a>
        </div>

        <div class="pop_body">
          <p class="msg">{{serviceInfo.description}}</p>
        </div>

        <div class="pop_btm">
          <button class="btn_r btn_blue btn_fill"><a :href="serviceInfo.link" target="_blank">바로 이동하기</a></button>
<!--          <button class="btn_r btn_blue btn_fill" @click="moveLink(serviceInfo.link)">바로 이동하기</button>-->
          <button class="btn_r btn_layerClose" @click="descriptionPop = false" style="min-width: 6rem; font-size: 1.5rem;">닫기</button>
        </div>
      </div>
    </div>
  </div>
  <!-- //container -->
</template>

<style scoped>
.card_list.type-c .card .cont {
  display: block;
}
</style>
