import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "@/store/store";
//import {createMetaManager} from "vue-meta";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@/assets/css/vue-datepicker.css'

// 공통 함수들
import {Common} from "@/utils/common";
//createApp(App).use(router).mount('#app')

import '@/assets/css/common.css'
import '@/assets/css/survey.css'
import '@/assets/css/slick.css'
import '@/assets/css/noto-sans-kr.css'
//import VueApexCharts from 'vue3-apexcharts'
//import i18n from "@/utils/i18n";


const app = createApp(App)

app.component('VueDatePicker', VueDatePicker);
app.use(router)
app.use(store)
app.use(Common)
//app.use(i18n)
//app.use(createMetaManager())
//app.use(VueApexCharts);

app.config.productionTip = false
app.config.errorHandler = (err, vm, info) => {
  // 에러 처리
  // `info`는 Vue 관련 에러 정보
  console.error(err)
  console.error(vm)
  console.error(info)
}
// app 전역 설정 변수
app.config.globalProperties.version = '1.0.0.'

/* router.isReady().then(()=> {

  const lang = i18n.global.locale.value
  store.commit("setLang", lang)

  app.mount('#app')
}) */
app.mount('#app')